import React, { useEffect } from "react";
import { useState } from "react";
import style from "styled-components";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import FormGroup from "@mui/material/FormGroup";
import { toast } from "react-toastify";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { MenuOpenSharp, ProductionQuantityLimits } from "@mui/icons-material";
import { responseErrorHandler } from "../../utils/errorHandler";
import { useGetAllAppointmentsQuery } from "../../redux/features/appointments/appointmentApiSlice";
import { HiMiniPencil, HiArchiveBox } from "react-icons/hi2";
import { storeAllAppointments } from "../../redux/features/appointments/appointmentSlice";
import { Box } from "@mui/material";

const styledProp = {
  position: "absolute",
  top: "80%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  marginTop: "150px",
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "black" : "#F59E0B",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "Inter, sans-serif",
    color: "#54565A",
    fontWeight: "600px",
    RxLetterSpacing: "0.5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "",
    color: "#6B7280",
    opacity: 0.7,
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 450,
    fontSize: "17px",
    lineHeight: "20px",
    borderstyle: "none",
    // filter: "drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.02))",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff",
  borderColor: "#FFFFFF",
  borderWidth: "1px",
  paddingTop: "10px",
  paddingBottom: "30px",
  height: "55px",
}));

export default function MenuPage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [menus, setMenus] = useState({});
  const [open, setOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dispatch = useDispatch();

  const {
    data: appointments,
    isLoading: appointmentLoading,
    isError: appointmentError,
  } = useGetAllAppointmentsQuery();

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = `${date.toLocaleDateString()}`;
    return formattedDate;
  };

  useEffect(() => {
    if (!appointmentLoading && appointments) {
      dispatch(storeAllAppointments(appointments.appointments));
    }
  });

  return (
    // <PageContainer>
    <div style={{ width: "1200px", overflow: "hidden" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "60px",
          marginBottom: "40px",
        }}
      >
        <MenuList>Appointments </MenuList>
        <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
              defaultChecked
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography> Toggle mode</Typography>
          </Stack>
        </FormGroup>
      </div>
      <TableContainer component={Paper} style={{ border: "none" }}>
        <Table sx={{ minWidth: 1180 }} aria-label="customized table">
          <TableHead borderStyle="none">
            <TableRow>
              <StyledTableCell align="left"> ID </StyledTableCell>
              <StyledTableCell align="left">NAME</StyledTableCell>
              <StyledTableCell align="left">EMAIL</StyledTableCell>
              <StyledTableCell align="left">PHONE</StyledTableCell>
              <StyledTableCell align="left">SERVICE</StyledTableCell>
              <StyledTableCell align="left">DATE</StyledTableCell>
              <StyledTableCell align="left">COMMENCED</StyledTableCell>
              <StyledTableCell align="left">ACTIONS</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: "green" }}>
            {!appointmentLoading &&
              appointments &&
              appointments?.appointments
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const { name, email, phone, service, date, commenced } = row;
                  return (
                    <>
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          align="left"
                          style={{
                            color: "#000",
                            fontWeight: 600,
                            fontSize: "12px",
                          }}
                        >
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            color: "black",
                            fontSize: "12px",
                            opacity: 0.65,
                          }}
                        >
                          {name}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            color: "black",
                            fontSize: "12px",
                            opacity: 0.65,
                          }}
                        >
                          {email}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            color: "black",
                            fontSize: "12px",
                            opacity: 0.65,
                          }}
                        >
                          {phone}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            color: "black",
                            fontSize: "12px",
                            opacity: 0.65,
                          }}
                        >
                          {service}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            color: "black",
                            fontSize: "12px",
                            opacity: 0.65,
                            fontWeight: "600",
                          }}
                        >
                          {formatDate(date)}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontSize: "12px",
                            opacity: 0.65,
                            fontWeight: "500px",
                            color: `${
                              commenced === false ? "#F59E0B" : "#22C55E"
                            }`,
                          }}
                        >
                          <div
                            style={{
                              background: `${
                                commenced === false ? "#F2C9CE" : "#DEF5E3"
                              }`,
                              width: "90px",
                              heigth: "10px",
                              borderRadius: "50px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {commenced.toString()}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            color: "black",
                            fontSize: "12px",
                            opacity: 0.65,
                            fontWeight: "600",
                          }}
                        >
                          <div style={{ display: "flex", gap: "15px" }}>
                            <HiMiniPencil
                              onClick={() => {
                                // setOpen(true);
                                // setMenus(row);
                              }}
                              style={{
                                color: "#de8f5f",
                                fontSize: "25px",
                                cursor: "pointer",
                              }}
                            />
                            <HiArchiveBox
                              style={{
                                color: "#de8f5f",
                                fontSize: "25px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOpen(true);
                                setMenus(row);
                              }}
                            />
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={appointments?.appointments?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <div style={{ display: open === true ? "block" : "none" }}>
        <Modal>
          <Box sx={styledProp}>
            <MText>View Appointment</MText>
            <MFormDiv>
              <MInputDiv>
                <InputField style={{ marginRight: "25px" }}>
                  <InputTest> Appointor Name </InputTest>
                  <Input defaultValue={menus.name} disabled type="text" />
                </InputField>
                <InputField>
                  <InputTest> Email </InputTest>
                  <Input defaultValue={menus.email} disabled type="text" />
                </InputField>
              </MInputDiv>
              <MInputDiv>
                <InputField style={{ marginRight: "25px" }}>
                  <InputTest>Phone </InputTest>
                  <Input defaultValue={menus.phone} disabled type="text" />
                </InputField>
                <InputField>
                  <InputTest> Service </InputTest>
                  <Input defaultValue={menus.service} disabled type="text" />
                </InputField>
              </MInputDiv>
              <MInputDiv>
                <InputField style={{ marginRight: "25px" }}>
                  <InputTest>Date </InputTest>
                  <Input defaultValue={menus.date} disabled type="text" />
                </InputField>
              </MInputDiv>
            </MFormDiv>
            {/* <ChoiceDiv>
              <TextOne onClick={() => setOpen(false)}>Cancel</TextOne>
              {isLoading ? (
                <>
                  <div>
                    <CreateButton>
                      <span>
                        <Spin
                          indicator={antIcon}
                          style={{ color: "white" }}
                          size="small"
                        />
                      </span>
                    </CreateButton>
                  </div>
                </>
              ) : (
                <TextTwo
                  style={{
                    display: menus.approved === false ? "block" : "none",
                  }}
                  onClick={approveProduct}
                >
                  Approve
                </TextTwo>
              )}
            </ChoiceDiv> */}
          </Box>
        </Modal>
      </div>
    </div>
    // </PageContainer>
  );
}

export const MenuList = style.p`

color: #000;
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 500;
line-height: 18px;
letter-spacing: -0.1px;
`;

const ModalDiv = style.div`
top: 0%;
left: 0%;
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background: rgba(101, 95, 95, 0.50);
z-index: 100;

`;

const Modal = style.div`
padding: 20px;
position: absolute;
top: 14%;
width: 800px;
// height: 550px;
border-radius: 5px;
background: var(--neutral-50, #FAFAFA);
`;

const MFormDiv = style.div`
width: 100%;
padding-left: 20px;
padding-right: 20px;


  
`;

const MInputDiv = style.div`
display: flex;
justify-content: space-between;
width: 100%;
margin-top: 10px;
  

`;
const InputField = style.div`
width: 100%;
`;

const Input = style.input`
width: 100%;
border-radius: 6px;
border: 1px solid var(--neutral-200, #E5E5E5);
background: var(--generic-white, #FFF);
padding: 8px 12px;
outline: none;
`;

const MText = style.p`
padding: 20px;
box-shadow: 0px 1px 0px 0px #e4e8ee;
color: var(--neutral-900, #171717);
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 24px;

  

`;

const InputTest = style.p`
color: var(--neutral-900, #171717);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
margin-bottom: 6px;

  
`;

const ImgDiv = style.div`
margin-top: 10px;
border-radius: 20px;
width: 130px;
height: 130px;
background: #FAFAFA;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.05);`;

const ChoiceDiv = style.div`
margin-top: 50px;
display: flex;
padding: 20px 30px;
justify-content: flex-end;
border-radius: 10px;
background: #FAEEDA;
`;

const TextOne = style.p`
color: #F59E0B;
display: flex;
padding: 6px 12px;
justify-content: center;
align-items: center;
border-radius: 4px;
background: #FCE1B4;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; 
margin-right: 25px;
cursor: pointer;

`;

const TextTwo = style.p`
color: #FFF;
display: flex;
padding: 6px 12px;
justify-content: center;
align-items: center;
border-radius: 4px;
background: #F59E0B;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; 
cursor: pointer;

`;

const CreateButton = style.button`
  border: 1px solid #f59e0b;
  border-radius: 4px;
  background: #f59e0b;
  padding: 6px 12px;
  color: #fff;
  text-align: justify;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
`;
