import React, { useRef, useState } from "react";
import { useGetSingleProjectQuery } from "../../redux/features/project/projectApiSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { styled } from "styled-components";

const ProjectDetails = () => {
  const { id } = useParams();
  const { data, isLoading, error } = useGetSingleProjectQuery(id);
  const navigate = useNavigate();

  // const handleNavigateWhatsapp = () => {
  //   navigate("https://wa.link/ct925y");
  // };

  return (
    <Container style={{ marginTop: "18rem" }}>
      <DetailWrapper>
        <div>
          <img
            src={!isLoading && data?.project?.image}
            max-width={800}
            width={"100%"}
            height={800}
          />
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "1px solid #ccc",
              padding: "10px 15px",
              borderRadius: "5px",
            }}
          >
            <h3>Property Features</h3>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "1rem",
                }}
              >
                <p>
                  <span>{!isLoading && data?.project?.description}</span>
                </p>
                <p>
                  <span>{!isLoading && data?.project?.location}</span>
                </p>
                <p>
                  <span>{!isLoading && data?.project?.price}</span>
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "1px solid #ccc",
              padding: "10px 15px",
              borderRadius: "5px",
              marginTop: "3rem",
            }}
          >
            <h3>To enquire about this property</h3>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1rem",
                }}
              >
                <p>
                  {" "}
                  <LocalPhoneIcon />
                </p>
                <p>
                  <span>08068287831</span>
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1rem",
                }}
              >
                <p>
                  <WhatsAppIcon />
                </p>
                <p>08080478644</p>
                <WhatsappButton>
                  <Link to="https://wa.link/ct925y">Whatsapp</Link>
                </WhatsappButton>
              </div>
            </div>
          </div>
        </div>
      </DetailWrapper>

      <Container>
        <h1 style={{ marginTop: "2rem", marginBottom: "1rem" }}>
          Other Images
        </h1>
        <MultipleImageWrapper>
          {!isLoading &&
            data &&
            data?.project?.otherImages.map((image, index) => {
              return <img src={image} alt="" width={400} height={400} />;
            })}
        </MultipleImageWrapper>
      </Container>
    </Container>
  );
};

export default ProjectDetails;

const MultipleImageWrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;
  gap: 1rem;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const DetailWrapper = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const WhatsappButton = styled.button`
  background-color: #fff;
  border: 1px solid #47a992;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 10px;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;
