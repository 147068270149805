import styled from "styled-components";

export const ContactSectionStyled = styled.div`
  /* width: 1454px; */
  height: 633px;
  flex-shrink: 0;
  background: #faf8ed;
  display: flex;
  flex-direction: row;
  gap: 15rem;
  p {
    color: #5f5f5f;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  h6 {
    color: var(--Primary1, #de8f5f);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  h2 {
    color: rgba(14, 36, 58, 0.7);
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 125.9%; /* 60.432px */
  }
  span {
    color: #d9d9d9;
  }
  a {
    color: #5a5a5a;
  }
`;

export const ContactPageStyle = styled.div`
  /* height: 209px; */
  padding: 12rem;
  text-align: center;
  background-color: #faf8ed;
  p {
    width: 300px;
    color: #5f5f5f;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
`;

export const ContentUp = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  /* gap: 38px; */
  h1 {
    color: rgba(14, 36, 58, 0.7);
    text-align: center;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    /* line-height: 113.9%; 54.672px */
  }
`;

export const LinkStyle = styled.a`
  a {
    color: var(--Primary1, #de8f5f);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.16px;
  }
`;

export const ContactBoxA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 30px; */
  padding: 12rem;
  padding-top: 5rem;
  input {
    display: flex;
    width: 512px;
    padding: 15px 20px;
    align-items: flex-start;
    margin-top: 3rem;
    margin-bottom: 3rem;
    gap: 10px;
    border-radius: 12px;
    border: 0;
    background: #f1f1f1;
  }

  textArea {
    display: flex;
    width: 512px;
    height: 180px;
    padding: 15px 22px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    background: #f1f1f1;
    border: 0;
  }

  button {
    width: 512px;
    height: 50px;
    flex-shrink: 0;
    margin-top: 2rem;
    border-radius: 15px;
    background: #101828;
    color: #fff;
  }
`;

export const ContactBoxB = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Inter;
  gap: 30px;
  padding: 12rem;
  padding-top: 5rem;
  color: #292d32;
  h2 {
    /* padding-top: -67px; */
    margin-top: -30px;
  }
  p {
    text-align: left;
    color: #b0aeae;
  }
`;

export const CircleStyles = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  /* margin: auto; */
`;

export const GrayBackground = styled.div`
  /* width: 1447px; */
  height: 547px;
  flex-shrink: 0;
  background: #faf8ed;
  padding: 12rem;

  h2 {
    color: #000;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 113.9%;
    text-align: center;
    padding-bottom: 3rem;
  }
  p {
    color: #5f5f5f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 157.9%;
  }
`;
