import React from "react";

import { BuildingStyled, FlexTop, ImageStyled } from "./styled";
import ChooseUs from "../../component/choose us/Choose";
import HomeProjectDisplay from "./HomeProjectDisplay";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import Text from "../../component/text/Text";
import Images from "../../component/images/Images";
import Blog from "../blog/Blog";
import Feedbacks from "../../component/feedbacks/Feedbacks";
import Partners from "../../component/certification/Partners";
import OurPartners from "../../component/partners/OurPartners";
import Services from "../../component/ourservices/Services";
import Vision from "../../component/vision/Vision";

const Home = () => {
  return (
    <>
      <div>
        <section>
          <Text />
        </section>
        <section>
          <Vision />
        </section>
        <HomeProjectDisplay />
        <section>
          <ChooseUs />
        </section>

        <section>
          <Partners />
        </section>
        <section>
          <Services />
        </section>

        <section>
          <Feedbacks />
        </section>

        <section>
          <Blog />
        </section>
        {/* <section>
          <OurPartners />
        </section> */}
      </div>
    </>
  );
};

export default Home;

export const Button = styled.button`
  margin-top: 1rem;
  padding: 15px;
  background-color: #df950e;
  color: #fff;
  font-family: Inter;
  font-weight: 400;
  border: 1px solid #de8f5f;
  border-radius: 8px;
  width: 200px;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: #000;
  }
`;
export const BookingButton = styled.button`
  padding: 15px;
  background-color: #de8f5f;
  color: #fff;
  font-family: Inter;
  font-weight: 400;
  border: 1px solid #de8f5f;
  border-radius: 8px;
  width: 200px;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: #000;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const BookingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  @media only screen and (max-width: 1000px) {
    margin-top: 6rem;
    margin-bottom: 3rem;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 6rem;
    margin-bottom: 3rem;
    display: block;
    /* flex-direction: column; */
    /* justify-content: center; */
  }
`;
