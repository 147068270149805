import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Spinner from "../../component/spinner/Spinner";
import { useGetAllBlogsQuery } from "../../redux/features/blog/blogApiSlice";
import { storeBlogs } from "../../redux/features/blog/blogSlice";
import BlogAuthor from "./BlogAuthor";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "../../component/feedbacks/Feedbacks";

const Blog = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const dispatch = useDispatch();
  const url = window.location.pathname;

  const navigate = useNavigate();

  const {
    data: blogs,
    isLoading: blogLoading,
    isError: blogError,
  } = useGetAllBlogsQuery();

  // Function to format the date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = `${date.toLocaleDateString()}`;
    return formattedDate;
  };

  const shortenDescription = (description) => {
    return description.length > 150
      ? description.substring(0, 150) + "..."
      : description;
  };

  useEffect(() => {
    if (!blogLoading && blogs) {
      dispatch(storeBlogs(blogs.blogs));
    }
  });

  return (
    <Container>
      <BlogHomeWrapper
        style={{
          marginTop: url === "/" ? "" : "15rem",
        }}
      >
        {
          <Button
            style={{ display: url === "/" ? "none" : "block" }}
            onClick={() => navigate("/post")}
          >
            Write a post
          </Button>
        }
        <h1
          style={{
            fontSize: "25px",
            fontFamily: "Montserrat",
            fontWeight: "500",
          }}
        >
          Our Recent News
        </h1>
        {url === "/" ? (
          <BlogHome>
            {blogLoading ? (
              <div
                style={{
                  position: "relative",
                }}
              >
                <Spinner />
              </div>
            ) : (
              !blogLoading &&
              blogs &&
              blogs?.blogs
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((card, index) => {
                  const { title, description, image, userId, createdAt, _id } =
                    card;

                  return (
                    <>
                      <Link to={`/blogpost/${_id}`}>
                        <Card key={index}>
                          <InnerCard>
                            <div>
                              <img src={image} alt="image1" />
                              <div style={{ display: "flex", gap: "2rem" }}>
                                <div>
                                  <BlogAuthor userId={userId} />
                                </div>
                              </div>
                              <div style={{ fontFamily: "Montserrat" }}>
                                {formatDate(createdAt)}
                              </div>
                              <h1 style={{ fontFamily: "Montserrat" }}>
                                {title}
                              </h1>
                              <p
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                {shortenDescription(description)}
                              </p>
                            </div>
                          </InnerCard>
                        </Card>
                      </Link>
                    </>
                  );
                })
            )}
          </BlogHome>
        ) : (
          <BlogHome>
            {blogLoading ? (
              <div
                style={{
                  position: "relative",
                }}
              >
                <Spinner />
              </div>
            ) : (
              !blogLoading &&
              blogs &&
              blogs?.blogs?.map((card, index) => {
                const { title, description, image, userId, createdAt, _id } =
                  card;

                return (
                  <>
                    <Link to={`/blogpost/${_id}`}>
                      <Card key={index}>
                        <InnerCard>
                          <div>
                            <img src={image} alt="image1" />
                            <div style={{ display: "flex", gap: "2rem" }}>
                              <div>
                                <BlogAuthor userId={userId} />
                              </div>
                            </div>
                            <div style={{ fontFamily: "Montserrat" }}>
                              {formatDate(createdAt)}
                            </div>
                            <h1 style={{ fontFamily: "Montserrat" }}>
                              {title}
                            </h1>
                            <p
                              style={{
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                              }}
                            >
                              {shortenDescription(description)}
                            </p>
                          </div>
                        </InnerCard>
                      </Card>
                    </Link>
                  </>
                );
              })
            )}
          </BlogHome>
        )}
      </BlogHomeWrapper>
    </Container>
  );
};

export default Blog;

const Button = styled.button`
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Montserrat;
  text-align: right;
  margin-bottom: 1rem;
  padding: 10px 15px;
  cursor: pointer;
`;

const BlogHomeWrapper = styled.div`
  font-family: Inter;
  padding-top: 12rem;
  color: #de8f5f;
  // background-color: #faf8ed;
  text-align: center;
`;
const BlogHome = styled.div`
  // max-width: 800px;
  // width: 100%;
  // padding: 5rem 12rem 5rem 12rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 8rem;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8rem;
  }
  @media (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

const Card = styled.div`
  margin: 3rem 0;
  max-width: 800px;
  width: 100%;
  height: auto;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  // background-color: #faf8ed;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  img {
    max-width: 100%;
    width: 100%;
    height: 300px;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 8px 8px 80px 0px rgba(0, 0, 0, 0.2);
  }
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 4rem;
  font-family: Inter;
  text-align: left;
  line-height: 20px;
  color: black;
`;
