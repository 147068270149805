import styled from "styled-components";

export const FlexTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12rem;
  padding-right: 14rem;
  padding-top: 20rem;
  gap: 10rem;
  /* background-color: #e4e4d0; */
  background-color: #faf8ed;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: start;
    padding-left: 4rem;
    flex-direction: column;
    gap: 10px;
    padding-top: 10rem;
  }
`;

export const BuildingStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #de8f5f;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 19rem;
  gap: 5rem;

  h2 {
    color: #000;
  }
  p {
    color: #5f5f5f;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    padding: 0 1rem;
  }
`;

export const ImageStyled = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    /* margin-left: -6rem; */
  }
`;
// choose us
export const MainStyled = styled.div`
  margin-top: 2rem;
  /* margin-bottom: 15rem; */
  height: 650px;
  flex-shrink: 0;

  /* background-color: #e4e4d0; */
  background-color: #faf8ed;
  h2 {
    display: block;
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 12rem;
    padding-top: 5rem;
  }
  @media only screen and (max-width: 1000px) {
    height: 100rem;
    h2 {
      /* text-align: center; */
      padding: 4rem;
    }
  }
`;

export const MainBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // padding: 12rem;

  img {
    width: 350px;
    height: 350px;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 3rem;
    padding: 4rem;

    img {
      width: 190px;
      height: 190px;
      margin-left: 30px;
      margin-top: -30px;
      /* align-items: center; */
    }
  }
  @media only screen and (max-width: 1000px) {
    padding-left: 4rem;
    gap: 2rem;
  }
`;

export const SmallBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: 600px;
  width: 100%;

  p {
    color: #5f5f5f;
    text-align: justify;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  h3 {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  @media only screen and (max-width: 1000px) {
    p {
      width: 450px;
      font-family: Inter;
      text-align: justify;

      font-size: 16px;

      font-weight: 200;
      line-height: normal;
    }
    h3 {
      width: 240px;
    }
  }
`;

export const FlexSmallBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: left;
  gap: 3rem;
  h2 {
    text-align: left;
  }
  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);

    gap: 3rem;
  }
  /* @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);

    gap: 3rem;
  } */
`;
export const BoxStyled = styled.div`
  width: 315px;
  height: 145px;
  @media only screen and (max-width: 1000px) {
    width: 200px;
    height: 80px;
    margin-top: 2rem;
  }
`;
