import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const Vision = () => {
  return (
    <Container>
      <Flex>
        <MissionParagraph>
          <ParagraphTitle>Our Mission</ParagraphTitle>
          Our mission is to provide comprehensive real estate services and
          valuation expertise, empowering clients with accurate insights and
          innovative solutions to achieve their property goals.
        </MissionParagraph>
      </Flex>
      <Flex>
        <VisionParagraph>
          <ParagraphTitle>Our Vision</ParagraphTitle>
          Our vision is to be among the most prominent real estate practicing
          firm highly regarded in Africa and the preferred place of employment
          for real estate professionals within the next Ten (10) years, known
          for international best practices, professionalism and integrity.
        </VisionParagraph>
      </Flex>
    </Container>
  );
};

export default Vision;

const VisionParagraph = styled.div`
  font-size: 14px;
  box-shadow: 2px 1px 2px 2px rgba(16, 24, 40, 0.05);
  padding: 10px 1rem;
  margin-top: 3rem;
  width: 600px;
  font-family: Montserrat;
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  color: #1f2937;
`;
const MissionParagraph = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  box-shadow: 2px 1px 2px 2px rgba(16, 24, 40, 0.05);
  padding: 10px 1rem;
  width: 600px;
  font-weight: 400;
  color: #1f2937;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  margin-top: 5rem;
`;

const ParagraphTitle = styled.p`
  font-size: 24px;
  font-weight: 600;
  font-family: Montserrat;
  color: #a0aec0;
  margin-bottom: 1rem;
`;
