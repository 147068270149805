import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { useGetAllProjectsQuery } from "../../redux/features/project/projectApiSlice";
import { useDispatch } from "react-redux";
import { storeProjects } from "../../redux/features/project/projectSlice";
import Spinner from "../../component/spinner/Spinner";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { ImLocation } from "react-icons/im";
import { RiPriceTagFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Container } from "../../component/feedbacks/Feedbacks";

const HomeProjectDisplay = () => {
  const { data: projects, isLoading: projectLoading } =
    useGetAllProjectsQuery();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!projectLoading && projects) {
      dispatch(storeProjects(projects.projects));
    }
  });

  return (
    <Container>
      <ProjectHomeWrapper>
        <h1
          style={{
            marginTop: "15rem",
            color: "#df950e",
          }}
        >
          Explore Available Properties
        </h1>
        <ProjectHome>
          {projectLoading ? (
            <div
              style={{
                position: "relative",
              }}
            >
              <Spinner />
            </div>
          ) : (
            !projectLoading &&
            projects &&
            projects?.projects?.map((card, index) => {
              const { name, location, image, price, _id } = card;
              return (
                <>
                  <Link to={`/projectDetails/${_id}`}>
                    <Card key={index}>
                      <img src={image} alt="image1" />
                      <HoverText className="hover-text">
                        Click to view details
                      </HoverText>
                      <Wrapper>
                        <div>
                          <ApartmentIcon
                            style={{ color: "#fff", fontSize: "20px" }}
                          />
                        </div>
                        <div>
                          <ProjectName style={{ color: "#FFF" }}>
                            {name}
                          </ProjectName>
                        </div>
                      </Wrapper>
                      <Wrapper>
                        <div>
                          <ImLocation
                            style={{ color: "#fff", fontSize: "20px" }}
                          />
                        </div>
                        <div>
                          <ProjectName style={{ color: "#FFF" }}>
                            {location}
                          </ProjectName>
                        </div>
                      </Wrapper>
                      <Wrapper>
                        <div>
                          <RiPriceTagFill
                            style={{ color: "#fff", fontSize: "20px" }}
                          />
                        </div>
                        <div>
                          <ProjectName style={{ color: "#FFF" }}>
                            N{price}
                          </ProjectName>
                        </div>
                      </Wrapper>
                    </Card>
                  </Link>
                </>
              );
            })
          )}
        </ProjectHome>
        {/* <Button>View More</Button> */}
      </ProjectHomeWrapper>
    </Container>
  );
};

export default HomeProjectDisplay;

const HoverText = styled.p`
  font-family: Montserrat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  font-weight: bold;
  visibility: hidden; // Initially hidden
`;

const ProjectName = styled.h4`
  text-align: left;
  color: #fff;
  font-size: 18px;
  font-family: Montserrat;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ProjectHomeWrapper = styled.div`
  font-family: Inter;
  padding-top: 2rem;
  padding-bottom: 5rem;
  color: #de8f5f;

  /* background-color: #e4e4d0; */
  // background-color: #faf8ed;
  text-align: center;
  @media only screen and (max-width: 768px) {
    h1 {
      text-align: left;
      padding: 4rem;
    }
  }
`;

const ProjectHome = styled.div`
  // margin-top: 1rem;
  display: grid;
  justify-items: center;
  padding-top: 2rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.5rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Card = styled.div`
  position: relative;
  max-width: 600px;
  width: 100%;
  height: 600px;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: #df950e;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  img {
    width: 100%;
    height: 500px;
    border-radius: 20px 20px 0 0;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 8px 8px 80px 0px rgba(0, 0, 0, 0.2);

    .hover-text {
      visibility: visible;
    }
  }

  @media (max-width: 768px) {
    // ... (your existing media query styles)
  }
`;
