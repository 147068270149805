import React from "react";
import { styled } from "styled-components";
import { useGetUserQuery } from "../../redux/features/auth/authApiSlice";
import { Text } from "./BlogSinglePost";

const BlogAuthor = ({ userId }) => {
  const { data, isLoading, error } = useGetUserQuery(userId);
  return (
    <Wrapper>
      <Text
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          fontFamily: "Montserrat",
        }}
      >
        Written by
      </Text>
      <Text style={{ fontSize: "14px", fontWeight: "bold", color: "#de8f5f" }}>
        {!isLoading && data ? data.user.lastName : <i>Anonymous</i>}
      </Text>
    </Wrapper>
  );
};

export default BlogAuthor;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: Montserrat @media (max-width: 450px) {
    flex-direction: column;
  }
`;
