import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLoginMutation } from "../redux/features/auth/authApiSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { setCredentials } from "../redux/features/auth/authSlice";
import { loginErrorHandler } from "../utils/errorHandler";
import { CircularProgress } from "@mui/material";

export default function LoginUser() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disable, setDisable] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [text, setText] = useState("Hide");
  const [showPassword, setShowPassword] = useState(false);

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginNewUser = async (e) => {
    e.preventDefault();
    try {
      const response = await login({ email, password }).unwrap();
      dispatch(setCredentials({ response, email }));

      if (response.user?.role === "admin") {
        console.log("Navigating to admin dashboard...");
        navigate("/admin/overview");
      } else {
        console.log("Navigating to home page...");
        navigate("/");
      }
    } catch (err) {
      let errorMessage = loginErrorHandler(err);
      toast.error(errorMessage);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    if (passwordShown === true) {
      setText("Hide");
    } else {
      setText("Show");
    }
  }, [passwordShown]);

  useEffect(() => {
    if (email === "" || password === "" || !email.includes("@")) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [email, password]);

  return (
    <div>
      <MainDiv>
        <FormContainer>
          <FormBox>
            <div>
              <NavLogoContainer>
                <NavLink to={"/"}>
                  <img src="/Assets/logo.png" width={80} height={50} />
                </NavLink>
              </NavLogoContainer>
            </div>
            <Text> Welcome Back </Text>
            <SignUpText>
              Sign in with your email address and Password
            </SignUpText>
            <Form>
              <InputDiv>
                <label htmlFor="email">Email address</label> <br />
                <InputField>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter your email address"
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputField>
              </InputDiv>
              <InputDiv>
                <label htmlFor="password">Password</label> <br />
                <PasswordDiv>
                  <input
                    id="password"
                    type={passwordShown ? "text" : "password"}
                    placeholder="Enter your password"
                    name="password"
                    autoComplete="off"
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <EyeIcon>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    {/* <p>{text}</p> */}
                  </EyeIcon>
                </PasswordDiv>
              </InputDiv>
              <SubDiv>
                <TextDiv>
                  <CheckBox>
                    <Round>
                      <input type="checkbox" id="checkbox" />
                      <label htmlFor="checkbox"></label>
                    </Round>
                  </CheckBox>

                  <p id="checkbox">Remember me </p>
                </TextDiv>
                <NavLink href="/forgotpassword">Forgot Password</NavLink>
              </SubDiv>

              {isLoading ? (
                <SubmitBtn>
                  <CircularProgress style={{ color: "#fff" }} size={20} />
                </SubmitBtn>
              ) : (
                <SubmitBtn
                  disabled={disable}
                  name="submit"
                  type="submit"
                  onClick={(e) => loginNewUser(e)}
                >
                  log in
                </SubmitBtn>
              )}
              <NoAccount>
                <p>Don&apos;t have an account? </p>
                <SignUpBtn href="/register">Sign Up</SignUpBtn>
              </NoAccount>
            </Form>
          </FormBox>
        </FormContainer>
      </MainDiv>
    </div>
  );
}

const NavLogoContainer = styled.div``;

const CheckBox = styled.div`
  margin-right: 14px;
  margin-bottom: 8px;
`;
const Round = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: #f48708;

  label {
    /* background-color: #fff; */
    border: 2px solid #f48708;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      border: 2px solid #f48708;
      border-top: none;
      border-right: none;
      content: "";
      height: 4px;
      left: 4px;
      opacity: 0;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 12px;
    }
  }

  input[type="checkbox"] {
    visibility: hidden;
  }
  input[type="checkbox"]:checked + label {
    border-color: #f48708;
  }
  input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
`;
const MainDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div`
  background-image: url("/Assets/login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    background: none;
  }
`;

const FormBox = styled.div`
  width: 486px;
  /* height: 580px; */
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;
  gap: 0;
  @media (max-width: 1000px) {
    /* width: 100vw; */
    /* height: 100%; */
  }
  img {
    margin-bottom: 30px;
  }
`;

const SubmitBtn = styled.button`
  border: none;
  margin-top: 50px;
  display: flex;
  cursor: pointer;
  padding: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #df950e;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:disabled {
    opacity: 0.25;
    background: #de8f5f;
    cursor: not-allowed;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const Text = styled.h4`
  color: #333;
  text-align: center;
  font-size: 32px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 7px;
  margin: 0 auto;
`;

const SignUpText = styled.h6`
  color: #333;
  text-align: center;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 auto;
`;

const InputDiv = styled.div`
  width: 100%;
  margin-top: 30px;
  input {
    width: 100%;
    height: 1.6rem;
    margin-top: 5px;
    outline: none;
    border: none;
    color: rgba(102, 102, 102, 0.6);
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  label {
    color: #666;
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const InputField = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  padding: 15px;
`;

const PasswordDiv = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const EyeIcon = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  p {
    color: rgba(102, 102, 102, 0.8);
    text-align: right;
    font-size: 18px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
    @media (max-width: 378px) {
      display: none;
    }
  }
`;
const SubDiv = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextDiv = styled.div`
  display: flex;
  align-items: center;
  p {
    color: #a3a3a3;
    font-size: 18px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 400px) {
      font-size: 14px;
    }
  }
`;

const NoAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  gap: 1;
  p {
    color: #666;
    font-size: 18px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 378px) {
      font-size: 13px;
    }
  }
`;

const SignUpBtn = styled.a`
  color: #666;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: underline;
  margin-left: 3px;
  cursor: pointer;
  @media (max-width: 378px) {
    font-size: 13px;
  }
`;
