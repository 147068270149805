import React, { useEffect } from "react";
import styled from "styled-components";

import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";

import { NavLink, Route, Routes } from "react-router-dom";
import AdminNav from "./AdminNav";
import { Table } from "@mui/material";
import TableComponent from "./TableComponent";
import OverView from "./OverView";
import Appointment from "./Appointment";
import Project from "../projects/Project";
import User from "./User";

const Admin = () => {
  const [screenWidth, setScreenWidth] = React.useState(window?.innerWidth || 0);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  return (
    <AdminPage>
      <AdminNav />
      <AdminTable>
        <Routes>
          <Route path="overview" element={<OverView />} />
          <Route path="project" element={<Project />} />
          <Route path="appointment" element={<Appointment />} />
          <Route path="user" element={<User />} />
        </Routes>
      </AdminTable>
    </AdminPage>
  );
};

const AdminPage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const AdminTable = styled.h1`
  padding: 3rem;
  min-height: 80vh;
  width: 75%;
`;

export default Admin;
