import React from "react";
import styled from "styled-components";
import { Logo } from "../header/Header";
import { Link, NavLink } from "react-router-dom";
import {
  AiFillTwitterCircle,
  AiOutlineMail,
  AiOutlineWhatsApp,
  AiFillFacebook,
  AiFillInstagram,
} from "react-icons/ai";

const Footer = () => {
  const date = new Date();
  const regularDate = date.getFullYear();
  return (
    <FooterStyled>
      <div>
        <NavLogoContainer>
          <NavLink to={"/"}>
            <img src="/Assets/logo.png" width={80} height={50} />
          </NavLink>
        </NavLogoContainer>
        <LinkStyled>
          <NavLink to="https://wa.link/ct925y">
            <AiOutlineWhatsApp />
          </NavLink>
          <NavLink to="/">
            {" "}
            <AiFillTwitterCircle />
          </NavLink>
          <NavLink to="/">
            {" "}
            <AiFillFacebook />
          </NavLink>
          <NavLink to="https://www.instagram.com/toyibayoade?igsh=ejBqOTA3NnRlamY3">
            {" "}
            <AiFillInstagram />
          </NavLink>
        </LinkStyled>
        <div>
          <p>All right Reserved Ayoade Toyyib Consulting {regularDate}</p>
        </div>
      </div>

      <AboutWrapper>
        <h4>About us</h4>
        <p>
          Ayoade Toyib Consulting is a qualified and licensed real estate firm
          registered with the Nigerian Institute of Estate Surveyors and Valuer
          and Estate Surveyor and valuer Registration Board of Nigeria.
        </p>
      </AboutWrapper>
      <ContactStyled>
        <h4>Our service</h4>
        <p>Property Management</p>
        <p>Real Estate Agency</p>
        <p>Real Estate Investment Appraisal</p>
        <p>Property Valuation</p>
        <p>Facility Management</p>
        <p>Title Documentation</p>
      </ContactStyled>
      <ContactStyled>
        <h4>Contact Us</h4>
        <Link to="tel:+2348068287831">
          Call us: <br /> +2348068287831, +2348080478644
        </Link>

        <br />
        <p>
          <span> ayoadetoyibconsulting@gmail.com</span>
          <span> info@ayoadetoyibconsulting.com.ng</span>
        </p>
        <br />
        <p>
          <span>Location: 2 Anisere close, Ojota Lagos </span> <br />
          <span> 21, Onikolobo Road, Opp. Korede Hospital, Abeokuta </span>
        </p>
      </ContactStyled>
    </FooterStyled>
  );
};

const NavLogoContainer = styled.div``;

const AboutWrapper = styled.div`
  max-width: 400px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    margin-top: -10px;
  }
`;

const FooterStyled = styled.div`
  height: auto;
  flex-shrink: 0;
  background-color: #292d32;
  display: flex;
  flex-direction: row;
  gap: 10rem;
  padding: 12rem;

  h4 {
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: #e8e7e7;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 6rem;
    height: 70rem;
    padding: 4rem;
  }
`;

const LinkStyled = styled.a`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  /* width: 30px;
  height: 30px; */
  padding-top: 2rem;
  flex-shrink: 0;
  a {
    font-size: 20px;
    color: #e8e7e7;
  }
`;

const ContactStyled = styled.div`
  h4 {
    padding-bottom: 2rem;
    font-family: Montserrat;
  }
  p {
    color: #e8e7e7;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  a {
    font-size: 16px;
    color: #e8e7e7;
    text-decoration: none;
    line-height: 30px;
    font-family: Montserrat;
  }
  @media only screen and (max-width: 768px) {
    margin-top: -60px;
  }
`;

export default Footer;
