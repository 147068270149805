"use client";
import React from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { useSelector } from "react-redux";
import { useGetAllUsersQuery } from "../../redux/features/auth/authApiSlice";
import { useGetAllProjectsQuery } from "../../redux/features/project/projectApiSlice";
import { useGetAllAppointmentsQuery } from "../../redux/features/appointments/appointmentApiSlice";
import {
  HiSquare3Stack3D,
  HiSquares2X2,
  HiUser,
  HiUserGroup,
} from "react-icons/hi2";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const Overview = () => {
  const {
    data: users,
    isLoading: userLoading,
    isError: userError,
  } = useGetAllUsersQuery();
  const {
    data: projects,
    isLoading: projectLoading,
    isError: projectError,
  } = useGetAllProjectsQuery();
  const { appointment } = useSelector((state) => state.appointment);
  const {
    data: appointments,
    isLoading: appointmentLoading,
    isError: appointmentError,
  } = useGetAllAppointmentsQuery();

  const revenueData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
    datasets: [
      {
        data: [75, 50, 65, 75, 85, 60, 75, 120, 100],
        backgroundColor: "transparent",
        borderColor: "blue",
        pointBorderColor: "transparent",
        pointBorderWidth: 4,
        paddingLeft: "9px",
        tension: 0.5,
      },
    ],
  };

  const customerData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
      "Feb",
    ],
    datasets: [
      {
        data: [0, 50, 65, 75, 85, 60, 75, 60, 60, 75, 90, 50, 20, 100, 60],
        backgroundColor: "transparent",
        borderColor: "#E3B05B",
        pointBorderColor: "transparent",
        pointBorderWidth: 4,
        paddingLeft: "9px",
        tension: 0.5,
      },
    ],
  };

  const options = {
    plugins: {
      legend: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
          // width: 900%
        },
      },
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 25,
          callback: (value) => value + "%",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <>
      <BoxContent>
        <TitleBox>
          <Title>Dashboard</Title>
          <SubTitle>Welcome to Dashboard</SubTitle>
        </TitleBox>

        <CardBox>
          <Card>
            <IconDiv>
              <HiSquares2X2 style={{ color: "#de8f5f", fontSize: "20px" }} />
            </IconDiv>
            <Text>
              <h2>{!projectLoading && projects && projects.projects.length}</h2>
              <p>Total Project</p>
            </Text>
          </Card>
          <Card>
            <IconDiv>
              <HiSquare3Stack3D
                style={{ color: "#de8f5f", fontSize: "20px" }}
              />
            </IconDiv>
            <Text>
              <h2>
                {!appointmentLoading &&
                  appointments &&
                  appointments?.appointments?.length}
              </h2>
              <p>Total Appointment</p>
            </Text>
          </Card>
          <Card>
            <IconDiv>
              <HiUser style={{ color: "#de8f5f", fontSize: "20px" }} />
            </IconDiv>
            <Text>
              <h2>{!userLoading && users && users?.users?.length}</h2>
              <p>Total Users</p>
            </Text>
          </Card>
          <Card>
            <IconDiv>
              <HiUserGroup style={{ color: "#de8f5f", fontSize: "20px" }} />
            </IconDiv>
            <Text>
              <h2>960</h2>
              <p>Total Clients</p>
            </Text>
          </Card>
        </CardBox>
        <GraphDiv>
          <GraphTitle>
            <Revenue>
              <h4>Revenue</h4>
              <p>Total Revenue</p>
            </Revenue>
            <Box>
              <button>Monthly</button>
              <button>Weekly</button>
              <button>Today</button>
            </Box>
          </GraphTitle>
          <RevenueGraph>
            <Line
              width={"2000px"}
              height={"700px"}
              data={revenueData}
              options={options}
            ></Line>
          </RevenueGraph>
        </GraphDiv>

        <SubGraph>
          <MapDiv>
            <MapBox>
              <MapTitle>
                <MapText>
                  <h4>Customer Map</h4>
                </MapText>
                <Box>
                  <button>Monthly</button>
                  <button>Weekly</button>
                  <button>Today</button>
                </Box>
              </MapTitle>
              <MapArea>
                <Line
                  data={customerData}
                  width={"2000px"}
                  height={"700px"}
                  options={options}
                ></Line>
              </MapArea>
              <DataBox>
                <p>Data Graph</p>
                <Open>
                  <p>Open</p>
                  <img
                    src="/Assets/externalLink.svg"
                    width={18}
                    height={18}
                    alt="Image"
                  />
                </Open>
              </DataBox>
            </MapBox>
          </MapDiv>
          <LayoutDiv>
            <Layout>
              <img
                src="/Assets/category.svg"
                width={34}
                height={38}
                alt="Image"
              />
              <Manage>
                <p>Manage</p>
                <p>Dashboard Layout</p>
              </Manage>
              <img
                style={{ marginTop: "60px" }}
                src="/Assets/arrow-right.svg"
                width={16}
                height={18}
                alt="Image"
              />
            </Layout>
          </LayoutDiv>
        </SubGraph>
      </BoxContent>
    </>
  );
};

export default Overview;

const BoxContent = styled.div``;
const TitleBox = styled.div``;
const Title = styled.h3`
  color: #000;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: bold;
  line-height: 18px; /* 64.286% */
  letter-spacing: -0.1px;
`;
const SubTitle = styled.p`
  color: #8f8f8f;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.1px;
  margin-top: 5px;
`;

const CardBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
  }
`;
const Card = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  width: 284px;
  padding: 40px 50px 40px 40px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #eaebf0;
  background: var(--white, #fff);

  /* MEd */
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.02);
`;
const IconDiv = styled.div`
  padding: 15px;
  border-radius: 200px;
  background: #f7fff0;
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  h2 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  p {
    color: #9f9f9f;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
`;

const GraphDiv = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #eaebf0;
  background: var(--white, #fff);
`;
const RevenueGraph = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
  }
`;

const GraphTitle = styled.div`
  display: flex;
  align-items: center;
  width: 478px;
  justify-content: center;
  gap: 106px;
  @media (max-width: 1024px) {
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
`;

const SubGraph = styled.div`
  display: flex;

  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
`;

const MapDiv = styled.div`
  border-radius: 15px;
  border: 1px solid var(--neutral-600, #eaebf0);
  width: 852px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const MapBox = styled.div``;

const MapArea = styled.div`
  /* height: 600px; */
  @media (max-width: 1024px) {
  }
`;

const DataBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  p {
    color: var(--gray-50, #5f6d7e);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.1px;
  }
`;
const Open = styled.div`
  display: flex;
  p {
    margin-right: 4px;
    color: var(--Secondary, #f48708);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
`;
const LayoutDiv = styled.div`
  width: 400px;
  height: 438px;
  border-radius: 10px;
  background: #3e4954;
  background-image: url(/Assets/blurbck.png);
  background-size: cover;
  background-repeat: no-repeat;
`;

const BlurImg = styled.div`
  position: absolute;
  margin-top: 20px;
  margin-right: 20px;

  float: right;
  position: relative;
`;

const Layout = styled.div`
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const Manage = styled.div`
  margin-top: 15px;
  p {
    color: var(--white, #fff);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
  }
`;

const Revenue = styled.div`
  h4 {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.14px;
  }
  p {
    color: #b3b0b0;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12px;
  }
`;

const Box = styled.div`
  display: flex;
  width: 204px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  background: #fbfaf9;
  height: 24px;
  button {
    cursor: pointer;
    border: none;
    background: none;
    color: #525252;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
    &:hover {
      color: #f59e0b;
      background: none;
      border-radius: 5px;
      background: #fff;
      padding: 5px 10px;
    }
  }
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
`;

const MapTitle = styled.div`
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutral-600, #eaebf0);
  padding: 16px 20px;
  margin: 3px;
  font-family: Inter;
`;

const MapText = styled.div`
  h4 {
    color: var(--gray-700, #272d37);

    /* Text L/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 150% */
    letter-spacing: -0.1px;
  }
`;
