import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { signupErrorHandler } from "../../utils/errorHandler";

import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
  validatePhoneNum,
  validateUsername,
} from "../../service/MenuValidationService";
import { CircularProgress } from "@mui/material";
import { LogoStyled } from "../../component/header/styled";
import { useCreateAdminAccountMutation } from "../../redux/features/auth/authApiSlice";

export default function CreateAdmin() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [activateButton, setActivateButton] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");

  const [text, setText] = useState("Hide");
  const [showPassword, setShowPassword] = useState(false);
  const [createAdminAccount, { isLoading }] = useCreateAdminAccountMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitForm = async (e) => {
    e.preventDefault();

    let validationFailed = false;
    if (!validateEmail(email)) {
      toast.error("Email is a compulsory field");
      validationFailed = true;
    }
    if (!validatePhoneNum(phoneNum)) {
      toast.error("Phone number is a compulsory field");
      validationFailed = true;
    }
    if (!validatePassword(password)) {
      toast.error("Password is a compulsory field");
      validationFailed = true;
    }
    if (!validateFirstName(firstName)) {
      toast.error("first name is a compulsory field");
      validationFailed = true;
    }
    if (!validateLastName(lastName)) {
      toast.error("last name is a compulsory field");
      validationFailed = true;
    }

    if (!validateUsername(username)) {
      toast.error("username is a compulsory field");
    }

    if (validationFailed) {
      return;
    }

    try {
      const data = {
        password,
        email,
        username,
        firstName,
        lastName,
        phoneNum,
      };

      const response = await createAdminAccount(data).unwrap();

      if (response) {
        toast.success("Successfully created user");
        navigate("/login");
      }
    } catch (err) {
      let errorMessage = signupErrorHandler(err);
      toast.error(errorMessage);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    if (passwordShown === true) {
      setText("Hide");
    } else {
      setText("Show");
    }
  }, [passwordShown]);

  return (
    <div>
      <MainDiv>
        <FormContainer>
          <FormBox>
            <div>
              <LogoStyled>
                <h1>SodMar</h1>
              </LogoStyled>
            </div>
            <Text> Create an account</Text>

            <Form>
              <Flex>
                <InputDiv>
                  <label htmlFor="Firstname">Firstname</label> <br />
                  <InputField>
                    <input
                      id="Firstname"
                      type="text"
                      placeholder="Enter your first name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </InputField>
                </InputDiv>
                <InputDiv>
                  <label htmlFor="Lastname">Lastname</label> <br />
                  <PasswordDiv>
                    <input
                      id="Lastname"
                      type="text"
                      placeholder="Enter your last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </PasswordDiv>
                </InputDiv>
              </Flex>
              <Flex>
                <InputDiv>
                  <label htmlFor="email">Email address</label> <br />
                  <InputField>
                    <input
                      id="email"
                      type="email"
                      placeholder="Enter your email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </InputField>
                </InputDiv>
                <InputDiv>
                  <label htmlFor="password">Password</label> <br />
                  <PasswordOnlyDiv>
                    <input
                      id="password"
                      type={passwordShown ? "text" : "password"}
                      placeholder="Enter your password"
                      autoComplete="off"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <EyeIcon>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </EyeIcon>
                  </PasswordOnlyDiv>
                </InputDiv>
              </Flex>
              <Flex>
                <InputDiv>
                  <label htmlFor="Username">Username</label> <br />
                  <InputField>
                    <input
                      id="Username"
                      type="text"
                      placeholder="Enter your username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </InputField>
                </InputDiv>
                <InputDiv>
                  <label htmlFor="phone">Phone Number</label> <br />
                  <PasswordDiv>
                    <input
                      id="phone"
                      type="tel"
                      placeholder="Enter your phone number"
                      value={phoneNum}
                      onChange={(e) => setPhoneNum(e.target.value)}
                    />
                  </PasswordDiv>
                </InputDiv>
              </Flex>
              {isLoading ? (
                <SubmitBtn type="submit" onClick={(e) => submitForm(e)}>
                  <CircularProgress style={{ color: "#FFF" }} />
                </SubmitBtn>
              ) : (
                <SubmitBtn type="submit" onClick={(e) => submitForm(e)}>
                  Create Admin Account
                </SubmitBtn>
              )}
              <NoAccount>
                <p>Already have an account? </p>
                <SignUpBtn href="/login">Sign in</SignUpBtn>
              </NoAccount>
            </Form>
          </FormBox>
        </FormContainer>
      </MainDiv>
    </div>
  );
}

const MainDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
`;

const FormContainer = styled.div`
  background-image: url("/signupBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    background: none;
  }
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  icon {
    color: red;
  }
`;

const FormBox = styled.div`
  width: 800px;
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;
  gap: 0;
  img {
    margin-bottom: 30px;
  }
`;

const SubmitBtn = styled.button`
  border: none;
  margin-top: 30px;
  display: flex;
  cursor: pointer;
  padding: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #de8f5f;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:disabled {
    opacity: 0.25;
    background: #bad343;
    cursor: not-allowed;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const Text = styled.h4`
  color: #333;
  text-align: center;
  font-size: 22px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 7px;
  margin: 0 auto;
`;

const InputDiv = styled.div`
  width: 100%;
  margin-top: 30px;
  input {
    width: 100%;
    height: 2rem;
    margin-top: 2px;
    outline: none;
    border: none;
    color: rgba(102, 102, 102, 0.6);
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  label {
    color: #666;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const InputField = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  padding: 13px;
`;

const PasswordOnlyDiv = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const PasswordDiv = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  padding: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const EyeIcon = styled.div`
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  p {
    color: rgba(102, 102, 102, 0.8);
    text-align: right;
    font-size: 18px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
    @media (max-width: 378px) {
      display: none;
    }
  }
`;

const NoAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  gap: 1;
  p {
    color: #666;
    font-size: 18px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 360px) {
      font-size: 13px;
    }
  }
`;

const SignUpBtn = styled.a`
  color: #666;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: underline;
  margin-left: 3px;
  cursor: pointer;
  @media (max-width: 378px) {
    font-size: 13px;
  }
`;
