import React from "react";
import styled from "styled-components";

const Partners = () => {
  return (
    <>
      <SecondSectionWrapper>
        <StoryHeading>Professional Body Membership</StoryHeading>

        <LogoContainer>
          <div>
            <LogoImage src="/Assets/esv.png" alt="" />
          </div>
          <div>
            <LogoImage src="/Assets/niesv2.png" alt="" />
          </div>
          <div>
            <LogoImage src="/Assets/ifma.png" alt="" />
          </div>
          <div>
            <LogoImage src="/assets/partner1.jpg" alt="" />
          </div>
          {/* - */}
        </LogoContainer>
      </SecondSectionWrapper>
    </>
  );
};

export default Partners;

const StoryHeading = styled.p`
  color: var(--midnight-blue, #141e61);
  text-align: center;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 39.2px; /* 81.667% */
  letter-spacing: 2.8px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const LogoImage = styled.img`
  width: 100px;

  @media (max-width: 1024px) {
    max-width: 100px;
    width: 100%;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  @media (min-width: 1440px) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem; /* Adjust the gap as needed */
  }

  @media (max-width: 450px) {
    gap: 2rem;
  }
`;

export const SecondSectionWrapper = styled.div`
  background: #fcfbf6;
  padding: 38px 0;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;

  @media (min-width: 1440px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 8rem;
  }
`;
