import React from "react";
import { useParams } from "react-router-dom";
import { useGetSingleBlogQuery } from "../../redux/features/blog/blogApiSlice";
import BlogAuthor from "./BlogAuthor";
import styled from "styled-components";
import { Container } from "../../component/feedbacks/Feedbacks";

const BlogSinglePost = () => {
  const { id } = useParams();
  const { data, isLoading, error } = useGetSingleBlogQuery(id);
  // Function to format the date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = `${date.toLocaleDateString()}`;
    return formattedDate;
  };

  return (
    <div
      style={{
        marginTop: "15rem",
      }}
    >
      <Container>
        <ImageWrapper>
          <ImageWrapper>
            <img src={!isLoading && data && data?.blog?.image} alt="" />
          </ImageWrapper>

          <Heading>{!isLoading && data?.blog?.title}</Heading>
          <Text>{!isLoading && data?.blog?.description}</Text>
        </ImageWrapper>
        <Wrapper>
          <div>
            <BlogAuthor userId={!isLoading && data?.blog?.userId} />
          </div>
          <Text>{!isLoading && formatDate(data?.blog?.createdAt)}</Text>
        </Wrapper>
      </Container>
    </div>
  );
};

export default BlogSinglePost;

const Heading = styled.h1`
  font-size: 24px;
  font-weight: 700;
  font-family: Montserrat;
`;

export const Text = styled.p`
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;
