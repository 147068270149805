import styled from "styled-components";

export const HeaderContainer = styled.div`
  font-family: Inter;
  max-width: 100%;
  position: fixed;
  width: 100%;
  margin-top: 2px;
  height: 70px;
  flex-shrink: 0;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  padding-left: 12rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 12rem;
  background-color: #faf8ed;
  /* background-color: #e4e4d0; */
`;

export const LogoStyled = styled.h1`
  color: #de8f5f;
`;

export const NavStyled = styled.ul`
  font-family: Inter;

  display: flex;
  width: 40%;
  justify-content: space-between;
  li {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 2rem;
  }

  a {
    text-decoration: none;
    color: #000;
    font-size: 12px;
  }
`;

export const ButtonStyled = styled.button`
  border-radius: 15px;
  background: #df950e;
  width: 100px;
  height: 43px;
  flex-shrink: 0;
  border: 0;
  color: #fff;
  margin: 1rem;
  cursor: pointer;
`;
export const ButtonStyledSignOut = styled.button`
  border-radius: 15px;
  background: var(--Primary1, #df950e);
  width: 100px;
  height: 43px;
  flex-shrink: 0;
  border: 0;
  color: #fff;
  margin: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: #000;
  }
`;
