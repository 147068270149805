import { React, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import emailjs from "@emailjs/browser";
import {
  CircleStyles,
  ContactBoxA,
  ContactBoxB,
  ContactPageStyle,
  ContactSectionStyled,
  ContentUp,
  GrayBackground,
  LinkStyle,
} from "./ContactStyles";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { AiOutlineMail } from "react-icons/ai";
import { Button } from "../home/Home";

const ContactBoxLeft = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xu0k3ks",
        "template_hgpl9rp",
        form.current,
        "D4X16UKWpRYMED4ZY"
      )
      .then(
        (result) => {},
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <h6>Contact Form</h6>
      <h2>Send Us A Message</h2>
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="user_name" required placeholder="Name" />

        <input type="text" name="email" required placeholder="Email" />

        <textarea
          name="message"
          required
          //   value={}
          //   onChange={}
          cols="30"
          rows="10"
        ></textarea>
        <button>Submit</button>
      </form>
    </>
  );
};

const ContactBoxRight = () => {
  const navigate = useNavigate();
  return (
    <>
      <h6>Contact info</h6>
      <h2>Contact Us</h2>

      <p>
        <span></span>
      </p>
      <div className="flexD">
        <CircleStyles>
          <AiOutlineMail />
        </CircleStyles>
        <p>+2348080478644</p>
      </div>
      <div className="flexD">
        <CircleStyles>
          <AiOutlineMail />
        </CircleStyles>
        <p>ayoadetoyibconsulting@gmail.com</p>
      </div>

      <div className="flexD">
        <CircleStyles>
          <HiOutlineLocationMarker />
        </CircleStyles>
        <p>Abeokuta Ogun state and Ojota Lagos</p>
      </div>
      <div>
        <p>Want to book and appointment?</p>

        <Button type="submit" onClick={() => navigate("/booking")}>
          Book now
        </Button>
      </div>
    </>
  );
};

const ContactSection = () => {
  return (
    <ContactSectionStyled>
      <ContactBoxA>
        <ContactBoxLeft />
      </ContactBoxA>
      <ContactBoxB>
        <ContactBoxRight />
      </ContactBoxB>
    </ContactSectionStyled>
  );
};

const MainContentUp = () => {
  return (
    <ContentUp>
      <LinkStyle>
        <NavLink>Home /</NavLink>
        <NavLink>Contact</NavLink>
      </LinkStyle>
      <h1>Contact Us</h1>
      <p>
        We understand that every project is unique, and we approach each one
        with a personalized touch. Our mission is to exceed your expectations,
        and we achieve this by focusing on quality, precision, and attention to
        detail in every aspect of our work.
      </p>
    </ContentUp>
  );
};

const LastContent = () => {
  return (
    <GrayBackground>
      <h2>Why Work with us </h2>
      <p>
        Finding the right real estate firm to handle your real estate services
        and needs is a very important task. Here at Ayoade Toyib Consulting we
        are qualified and licensed real estate firm registered with the Nigerian
        Institute of Estate Surveyors and Valuer and Estate Surveyor and valuer
        Registration Board of Nigeria with expertise in Property management,
        Property valuation for all purpose, Real estate agency, Real estate
        investment appraisal, Facility management and Title documentation.Our
        expertise cut across residential, commercial and Industrial properties.
        We also provide real estate advisory services; delivering high level
        solutions in areas of development conception, design and build,
        development appraisal and monitoring among others. Our competitive edge
        includes our domain expertise, track record, excellent management,
        innovation and strong corporate values. With a team of highly motivated
        experienced Estate Surveyors in the field, we possess the professional
        skills required in all our business offerings, the ultimate goal is to
        deliver superior value to our clients. Our vision is to be a global real
        estate firm, distinctive for superior value delivery to our customers,
        investors and employees with a mission to help our customers across
        geography and class experience superior satisfaction through our quality
        services.
      </p>
    </GrayBackground>
  );
};

const Contact = () => {
  return (
    <>
      <ContactPageStyle>
        <MainContentUp />
      </ContactPageStyle>
      <ContactSection />
      <LastContent />
    </>
  );
};

export default Contact;
