import { useNavigate } from "react-router-dom";
import { Button } from "../../pages/home/Home";
import { BuildingStyled } from "../../pages/home/styled";
import styled from "styled-components";
import { Container } from "../feedbacks/Feedbacks";

const Text = () => {
  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate("/register");
  };
  return (
    <Container>
      <BuildingStyled>
        <ContentWrapper>
          <h2>Find your property at</h2>
          <h3 style={{ color: "#df950e" }}>Ayoade Toyib Consulting</h3>
          <p>
            Selecting the appropriate real estate firm to cater to your real
            estate needs is crucial. At Ayoade Toyib Consulting, we are a
            certified and licensed firm registered with the Nigerian Institute
            of Estate Surveyors and Valuers, Estate Surveyor and Valuer
            Registration Board of Nigeria, and International Facility Management
            Association of Nigeria. Our expertise spans property management,
            valuation, real estate agency, investment appraisal, facility
            management, and title documentation for residential, commercial, and
            industrial properties. Additionally, we offer advisory services
            covering development conception, design and build, appraisal, and
            monitoring. Our competitive advantage lies in our domain knowledge,
            proven track record, effective management, innovation, and strong
            corporate values. Backed by a team of highly skilled and motivated
            Estate Surveyors, we are committed to delivering superior value to
            our clients. We eagerly await the opportunity to serve you as our
            esteemed client.
          </p>
          <Button type="submit" onClick={handleSignUp}>
            Get Started
          </Button>
        </ContentWrapper>
        <div>
          <Image src="/Assets/oshoba1.jpg" alt="build" />
        </div>
      </BuildingStyled>
    </Container>
  );
};

export default Text;

export const Image = styled.img`
  max-width: 700px;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 8px 8px 80px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const ContentWrapper = styled.div`
  max-width: 600px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 450px;
    width: 100%;
  }
`;
