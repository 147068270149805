import React from "react";
import styled from "styled-components";
import { Container } from "../feedbacks/Feedbacks";
import ApprovalIcon from "@mui/icons-material/Approval";
import { StoryHeading } from "../partners/OurPartners";

const serviceData = [
  {
    id: 1,
    title: "Property Management",
    subtitle:
      "Property management involves the administration, operation, and oversight of real estate properties on behalf of the owner. This includes tasks such as rent collection, property maintenance, tenant relations, lease agreements, and financial management. The goal is to ensure that the property operates smoothly and generates income while maintaining its value over time.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },
  {
    id: 2,
    title: "Real Estate Agency",
    subtitle:
      "Real estate agency is carried  out by a Firm, company or organization that facilitates the buying, selling, or renting of properties on behalf of clients. They typically employ licensed real estate agents who act as intermediaries between buyers and sellers, helping to negotiate transactions ensure that the buyer is not buying above the market price and the seller is not selling below the market value and also to ensure legal compliance.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },
  {
    id: 3,
    title: "Real Estate Investment Appraisal",
    subtitle:
      "Real estate investment appraisal is the process of evaluating the financial feasibility and potential profitability of investing in a real estate property. This involves analyzing factors such as the property's market value, income potential, operating expenses, risk assessment, and projected returns to determine whether the investment is viable and aligns with the investor's goals.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },

  {
    id: 4,
    title: "Property Valuation/Appraisal",
    subtitle:
      "Property valuation or appraisal is the process of determining the monetary value of an Asset be it Tangible or Intangible. It involves evaluating various factors such as location, size, condition, comparables, and market trends to arrive at an estimate of how much the Asset is worth. Valuations are crucial for various purposes including buying, selling, insurance, taxation, financial, investment decision-making and so on.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },
  {
    id: 5,
    title: "Facility Management",
    subtitle:
      "Facility management involves overseeing and maintaining the physical assets of an organization to ensure they operate effectively and efficiently. This includes managing buildings, equipment, utilities, and services to support the organization's core business functions. Facility managers handle tasks such as maintenance, security, cleaning, space planning, and ensuring compliance with regulations to create safe, productive, and comfortable environments for employees and visitors.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },
  {
    id: 6,
    title: "Title Documentation",
    subtitle:
      "Title Documentation is an act  of been responsible for acquiring and managing legal documents related to property titles on behalf of a client. This includes obtaining fresh Certificate of Occupancy, Seeking Governors Consent on real estate transactions, Applying for Government Allocation etc",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },
];

const Services = () => {
  const shortenDescription = (description) => {
    return description.length > 250
      ? description.substring(0, 250) + "..."
      : description;
  };

  return (
    <Container>
      <h1>
        {" "}
        <StoryHeading style={{ marginTop: "2rem" }}>
          Our <span style={{ color: " #df950e" }}>Services</span>
        </StoryHeading>
      </h1>

      <div>
        <BlogHome>
          {serviceData?.map((card, index) => {
            const { title, subtitle, icon } = card;

            return (
              <>
                <Card key={index}>
                  <InnerCard>
                    <div>
                      <p style={{ marginTop: "1rem" }}>{icon}</p>
                      <h1
                        style={{ fontFamily: "Montserrat", color: " #df950e" }}
                      >
                        {title}
                      </h1>
                      <p
                        style={{
                          fontSize: "12px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {shortenDescription(subtitle)}
                      </p>
                    </div>
                  </InnerCard>
                </Card>
              </>
            );
          })}
        </BlogHome>
      </div>
    </Container>
  );
};

export default Services;

const Button = styled.button`
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Montserrat;
  text-align: right;
  margin-bottom: 1rem;
  padding: 10px 15px;
  cursor: pointer;
`;

const BlogHomeWrapper = styled.div`
  font-family: Inter;
  padding-top: 12rem;
  color: #de8f5f;
  // background-color: #faf8ed;
  text-align: center;
`;
const BlogHome = styled.div`
  // max-width: 800px;
  // width: 100%;
  // padding: 5rem 12rem 5rem 12rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: center;
  gap: 3rem;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  @media (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

const Card = styled.div`
  margin: 3rem 0;
  max-width: 800px;
  width: 100%;
  height: auto;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50% 20px 20px 20px;
  // background-color: #faf8ed;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  img {
    max-width: 100%;
    width: 100%;
    height: 300px;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 8px 8px 80px 0px rgba(0, 0, 0, 0.2);
  }
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem;
  font-family: Inter;
  text-align: left;
  line-height: 20px;
  color: black;
`;
