import React, { useState } from "react";
import Rating from "@mui/material/Rating";
import styled from "styled-components";

const feedbacks = [
  {
    image: <img src="/Assets/client1.jpg" width={70} height={70} alt="img" />,
    text: "They do an excellent job, always time conscious and offer most competitive rate in town",
    name: "Azesod Global RES LEV Limited",
    occu: "Client",
  },
  {
    image: <img src="/Assets/client2.jpg" width={70} height={70} alt="img" />,
    text: "Their real estate services is coupled with collaborative and innovative spirit which made them the go-to-expert for my most significant projects when Real Estate service is required",
    name: "Adesoji Adeniyi",
    occu: "Client",
  },
  {
    image: <img src="/Assets/client3.jpg" width={70} height={70} alt="img" />,
    text: "Ayoade Consulting is one of the best real estate consult in town, they are reliable and trustworthy in rendering their services",
    name: "Mogaji Ismail",
    occu: "Client",
  },
  {
    image: <img src="/Assets/client4.jpg" width={70} height={70} alt="img" />,
    text: "Ayoade Toyib Consulting has high level of integrity and maintains a satisfactory business relationship, I will be glad to recommend him.",
    name: "Mr. M.A Akanni",
    occu: "Client",
  },
];

const Feedbacks = () => {
  const [value, setValue] = useState(5);
  return (
    <Container>
      <HeadingFlex>
        <Heading>Client's Feedback</Heading>
        <div style={{ paddingBottom: "6rem" }}>
          <TextParagraph>
            At Ayoade Toyib Consulting we strive to provide exceptional service
            to all our clients, and their satisfaction is our top priority. We
            greatly value their feedback as it helps us understand their
            experience and how we can improve our services further. Here are few
            of our clients feedback that might interest you:
          </TextParagraph>
        </div>
        <GridFeed>
          {feedbacks.map((feed, index) => (
            <Flex key={index}>
              <FlexContainer>
                <RoundImage>{feed.image}</RoundImage>
                <div>
                  <Rating
                    name="read-only"
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    readOnly
                  />
                </div>
              </FlexContainer>
              <Paragraph>{feed.text}</Paragraph>
              <div>
                <Name>{feed.name}</Name>
                <Occu>{feed.occu}</Occu>
              </div>
            </Flex>
          ))}
        </GridFeed>
      </HeadingFlex>
    </Container>
  );
};

export default Feedbacks;

const RoundImage = styled.div`
  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const HeadingFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 5rem;

  @media (max-width: 450px) {
    margin-top: 2rem;
  }
  @media (max-width: 1200px) {
    margin-top: 2rem;
  }
`;

const Heading = styled.span`
  font-family: Montserrat;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
`;
const Text = styled.p`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000000;
  padding: 0 20px;
  @media (min-width: 1440px) {
    padding: 0px;
    font-size: 20px;
  }
`;
const TextParagraph = styled.p`
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000000;
  padding: 0 20px;
  @media (min-width: 1440px) {
    padding: 0px;
    font-size: 20px;
  }
`;
const GridFeed = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  @media (min-width: 375px) {
    padding: 0 20px;
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1440px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 0px;
  }
`;
const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  background-color: #fff;
  border: 0.5px solid #f59e0b;
  padding: 3rem;
  border-radius: 10px;
  gap: 1rem;
  box-shadow: 0px 20px 60px rgba(81, 102, 112, 0.12);
`;
const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 1rem;
`;
const Paragraph = styled.p`
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */
  font-family: Inter;
  color: #434343;
`;
const Name = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 5px;
  color: #000000;
`;
const Occu = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: Montserrat;
  color: #949494;
`;
