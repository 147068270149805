import {
  BoxStyled,
  BuildingStyled,
  MainBox,
  MainStyled,
  SmallBox,
} from "../../pages/home/styled";
import { initialData } from "../../service/data";

import { BookingButton, BookingWrapper, Button } from "../../pages/home/Home";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Container } from "../feedbacks/Feedbacks";
import { ContentWrapper } from "../text/Text";
const Box = () => {
  return (
    <>
      {initialData?.map((data, index) => {
        const { tittle, paragraph } = data;
        return (
          <BoxStyled key={index}>
            <h3>{tittle}</h3>
            <br />
            <p>{paragraph}</p>
          </BoxStyled>
        );
      })}
    </>
  );
};
const ChooseUs = () => {
  const navigate = useNavigate();

  return (
    // <MainStyled>
    //   <Container>
    //     <h2>
    //       Why <br />
    //       Choose Us?
    //     </h2>
    //     <MainBox>
    //       <ImageWrapper>
    //         <Image src="/Assets/long.jpg" alt="" />
    //       </ImageWrapper>
    //       <SmallBox>
    //         <Text>
    //           Finding the right real estate firm to handle your real estate
    //           services and needs is a very important task. Here at Ayoade Toyib
    //           Consulting we are qualified and licensed real estate firm
    //           registered with the Nigerian Institute of Estate Surveyors and
    //           Valuer and Estate Surveyor and valuer Registration Board of
    //           Nigeria with expertise in Property management, Property valuation
    //           for all purpose, Real estate agency, Real estate investment
    //           appraisal, Facility management and Title documentation.Our
    //           expertise cut across residential, commercial and Industrial
    //           properties. We also provide real estate advisory services;
    //           delivering high level solutions in areas of development
    //           conception, design and build, development appraisal and monitoring
    //           among others. Our competitive edge includes our domain expertise,
    //           track record, excellent management, innovation and strong
    //           corporate values.
    //         </Text>
    //         <BookingWrapper>
    //           <BookingButton onClick={() => navigate("/booking")}>
    //             Book Now
    //           </BookingButton>
    //         </BookingWrapper>
    //       </SmallBox>
    //     </MainBox>
    //   </Container>
    // </MainStyled>

    <Container>
      <BuildingStyled>
        <div>
          <Image src="/Assets/oshoba1.jpg" alt="build" />
        </div>
        <ContentWrapper>
          <h2>Why Choose us?</h2>
          <p style={{ fontSize: "14px" }}>
            We are professionals and licensed real estate firm dedicated to
            providing unparalleled professionalism in all Real Estate Services.
            With our expertise and commitment to excellence, we aim to exceed
            our clients' expectations and elevate their real estate experience
            in order to build a long-term relationships based on trust,
            integrity, and exceptional services.
          </p>
          <Button type="submit" onClick={() => navigate("/booking")}>
            Book now
          </Button>
        </ContentWrapper>
      </BuildingStyled>
    </Container>
  );
};

export default ChooseUs;

const Text = styled.p`
  max-width: 700px;
  width: 100%;
  font-family: Inter;
  font-size: 16px;
  padding: 0 2rem;
`;

const ImageWrapper = styled.div`
  max-width: 600px;
`;

const Image = styled.img`
  max-width: 700px;
  height: 700px;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  @media (max-width: 768px) {
    max-width: 800px;
    width: 100%;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 8px 8px 80px 0px rgba(0, 0, 0, 0.2);
  }
`;
